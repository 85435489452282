import { Link, withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import clsx from 'clsx';
import ReactCodeInput from 'react-verification-code-input';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import Warning from 'pages/home/code_verification/warning.svg';
import Loader from 'components/loaders/Loader';
import queryString from 'query-string';
import { getBaseUrlForRedirect } from 'helper/commonFunctions';
import AuthEvents from 'events/AuthEvents';
import styles from '../styles';
import HomepageWrapper from '../HomepageWrapper';

const VerifyCodeForm = ({ loading, expired, classes, onResend, onVerify, incorrect, resendLoading, locationState, flashMessage, showSignIn, isPremium }) => {
    const [code, setCode] = useState('');

    const { companySlug, enteredEmail, initialLocation, redirectUrl } = locationState;

    const isRegistration = Boolean(initialLocation) && initialLocation.pathname.includes('registration');
    const baseRedirectUrl = redirectUrl || '/welcome?';

    const login = (refreshToken, slug) => {
        if (!slug) {
            flashMessage('Something went wrong', 'error');
            return;
        }
        AuthEvents.USER_LOGIN({ email: enteredEmail || '' });
        const isPremiumParam = isPremium ? '&isPremium=true' : '';
        window.location = `${getBaseUrlForRedirect(slug)}${baseRedirectUrl}&token=${refreshToken}${isPremiumParam}`;
    };

    const redirectForward = (refreshToken, companySlugProp) => {
        const parsed = queryString.parse(initialLocation.search);
        const stringified = queryString.stringify(parsed);

        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('companySlug', companySlug || companySlugProp);

        const strLocationState = queryString.stringify(initialLocation.state);
        window.location = `${getBaseUrlForRedirect(companySlug || companySlugProp)}/my-assessments?token=${refreshToken}&${strLocationState}${stringified}`;
        localStorage.removeItem('refreshToken');
    };

    const handleChange = (vals) => {
        setCode(vals);
    };

    const handleVerify = () => {
        onVerify(code, (data) => {
            localStorage.setItem('wasAuthorized', 1);
            if (!isRegistration) {
                login(data.refreshToken, data.companySlug);
            } else {
                redirectForward(data.refreshToken, data.companySlug);
            }
        });
    };

    return (
        <HomepageWrapper noSidebar>
            <h1 className={expired ? classes.heading2 : classes.heading}>{expired ? 'Your verification session has expired. Try resending the code.' : "Let's verify your account"}</h1>
            {!expired && <>
                <p className={clsx(classes.text, 'u-txt--left')}>
                    We have sent a 6 digit verification code to your email. Enter this code below to verify and continue.
                </p>
                <ReactCodeInput
                    onChange={e => handleChange(e)}
                    fields={6}
                    fieldWidth={71.5}
                    fieldHeight={63}
                    className={classes.verificationInput}
                />
                {incorrect && <div className={classes.errorMessage}><img className="u-mrg--rx2" src={Warning} alt="!" />Invalid verification code</div>}
            </>}
            <div className="u-pos--relative">
                { expired
                    ? (
                        <Button
                            className={classes.signInButton}
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={resendLoading}
                            onClick={onResend}
                        >
                            Resend code
                        </Button>
                    ) : (
                        <Button
                            className={classes.signInButton}
                            variant="contained"
                            color="primary"
                            fullWidth
                            disabled={code.length !== 6 || loading}
                            onClick={handleVerify}
                        >
                            Verify
                        </Button>
                    )
                }
                {
                    loading && (
                        <Loader
                            className={classes.loader}
                            size={24}
                            show
                            thickness={2}
                        />
                    )
                }
            </div>
            <div className="u-dsp--f u-jc--sb">
                {expired ? <div /> : (
                    <Button
                        className={classes.resendButton}
                        color="primary"
                        onClick={onResend}
                        disabled={resendLoading}
                    >
                        Resend code
                    </Button>
                )}
                {showSignIn && (
                    <p className="u-mrg--tx2 u-txt--semibold">
                        Already have an account?&nbsp;
                        <Link to="/login" className={classes.link} color="primary">Sign in</Link>
                    </p>
                )}
            </div>
        </HomepageWrapper>
    );
};

export default withRouter(withStyles(styles)(VerifyCodeForm));
