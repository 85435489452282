/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import clsx from 'clsx';
import Dialog from 'libraries/Dialog';
import TextInput from 'libraries/TextInput';
import { appCtx } from 'components/appStore';
import PreviewScript from 'components/marketplace/Preview/SimplePreview';
import LargeCheckIcon from 'img/large_check.svg';
import QuestionIcon from 'img/question.svg';
import TimerIcon from 'img/timer2.svg';
import { QuestionTypes } from 'helper/questionSettingsFunctions';
import { generateAssessment, generateAssessmentSkills, generateAssessmentSkillSet, getGeneratedCompletedQuestions,
    uploadGenerationFile, reUploadGenerationFile, compoundGeneratedQuestionTypes, saveGeneratedAssessment
} from 'requests/ScriptRequests';
import Zendesk from 'helper/zendeskFunctions';
import Sidebar from './components/Sidebar';

import stylesJs from './styles';

const mockAssessmentData = {
    time: 90
};

const renderQuestioType = (Icon, text) => (
    <div className="u-dsp--f u-ai--center">
        { Icon && (
            <Icon style={{
                width: '24px',
                height: '24px',
                marginRight: '15px'
            }}
            />
        ) }
        <span>{text}</span>
    </div>
);

const TTAiGeneratorDialog = ({ open, flashMessage, classes, history }) => {
    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('Analysing');
    const [loadingFile, setLoadingFile] = useState(false);
    const [generatedUuid, setGeneratedUuid] = useState(null);
    const [generatedAssessment, setGeneratedAssessment] = useState(null);
    const [currentStep, setCurrentStep] = useState(1);
    const [uploadedFile, setUploadedFile] = useState(null);
    const [jobDescription, setJobDescription] = useState('');

    const { closeTTAiGeneratorDialog } = useContext(appCtx);

    useEffect(() => {
        if (!open) {
            setDefaultValues();
            Zendesk.open();
            return;
        }
        Zendesk.hide();
    }, [open]);

    const setDefaultValues = () => {
        setLoading(false);
        setCurrentStep(1);
        setJobDescription('');
        setUploadedFile(null);
    };

    const onSubmit = async () => {
        setLoading(true);
        setCurrentStep(0);
        let genUuid = generatedUuid;
        try {
            if (!uploadedFile) {
                const { data: { uuid } } = await generateAssessment({
                    jobDescription
                });
                genUuid = uuid;
            }
            setLoadingText('Generating Assessment');
            await generateAssessmentSkills(genUuid);
            await generateAssessmentSkillSet(genUuid);
            await compoundGeneratedQuestionTypes(genUuid);
            const { data } = await getGeneratedCompletedQuestions(genUuid);
            setGeneratedUuid(genUuid);
            setGeneratedAssessment(data);
            setCurrentStep(2);
        } catch (e) {
            onRestart();
            flashMessage("Oops! Something’s not quite right on our end. Our team is on it, and we'll be back as soon as possible.", 'error');
        } finally {
            setLoadingText('Analysing');
            setLoading(false);
        }
    };

    const handleClose = () => {
        closeTTAiGeneratorDialog();
    };

    const handleJobDescription = (e) => {
        setJobDescription(e.target.value);
    };

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        if (file) {
            const maxFileSize = 10 * 1024 * 1024;
            if (file.size > maxFileSize) {
                flashMessage('The file should not be empty and its size should be 10MB or less', 'error');
                return;
            }
            formData.append('file', file);
            setLoadingFile(true);
            if (generatedUuid && uploadedFile) {
                reUploadGenerationFile(formData, generatedUuid).then(({ data, success }) => {
                    if (success) {
                        setUploadedFile(file);
                        setGeneratedUuid(data.uuid);
                    }
                }).finally(() => setLoadingFile(false));
            } else {
                uploadGenerationFile(formData).then(({ data, success }) => {
                    if (success) {
                        setUploadedFile(file);
                        setGeneratedUuid(data.uuid);
                    }
                }).finally(() => setLoadingFile(false));
            }

            e.target.value = '';
        }
    };

    const onRestart = () => {
        setUploadedFile(null);
        setJobDescription('');
        setCurrentStep(1);
    };

    const onSave = (redirectToInvite = false) => {
        saveGeneratedAssessment(generatedUuid).then(({ success, data }) => {
            if (success) {
                closeTTAiGeneratorDialog();
                if (redirectToInvite) {
                    history.push(`/script/invite/${data.slug}/invitation`);
                }
            }
        });
    };

    const disabledButton = !uploadedFile && !jobDescription.length;
    const disableUpload = jobDescription.length > 0 || loadingFile;

    const renderActionComponent = () => {
        if (loading) {
            return null;
        }

        if (currentStep === 3) {
            return (
                <Button
                    color="primary"
                    variant="text"
                    className={classes.startAgainButton}
                    onClick={onRestart}
                >
                    Start again, create a new assessment for another role
                </Button>
            );
        }

        return (
            <>
                {
                    (Boolean(uploadedFile) || jobDescription.length > 0) && (
                        <Button
                            color="primary"
                            variant="text"
                            className={classes.nextButton}
                            onClick={onRestart}
                        >
                            Restart
                        </Button>
                    )
                }
                <Button
                    color="primary"
                    variant="contained"
                    className={classes.nextButton}
                    onClick={onSubmit}
                    disabled={disabledButton}
                >
                    Next
                </Button>
            </>
        );
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            handleClose={handleClose}
            fullScreen
            actionComponent={renderActionComponent()}
            contentClassName={classes.root}
            actionClassName={classes.actions}
        >
            {loading && (
                <div className={classes.loading}>
                    <CircularProgress
                        size={60}
                    />
                    <div className="text">{loadingText}</div>
                </div>
            )}
            <div className={clsx(classes.wrapper, 'u-dsp--f')}>
                {
                    currentStep === 1 && (
                        <>
                            <Sidebar />
                            <div className={classes.content}>
                                <div className={classes.title}>Upload</div>
                                <div className={clsx(classes.uploadArea, disableUpload && 'disabled')}>
                                    {
                                        loadingFile && (
                                            <div className={classes.fileLoadingOverlay}>
                                                <CircularProgress
                                                    size={30}
                                                />
                                            </div>
                                        )
                                    }
                                    { uploadedFile ? (
                                        <>
                                            <img src={LargeCheckIcon} className="u-mrg--bx2" alt="" />
                                            <div className="helperText">{uploadedFile.name} uploaded</div>
                                            <div>
                                                <label htmlFor="file-upload" className={classes.baseButton}>Re-upload</label>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <label htmlFor="file-upload" className="u-dsp--f u-jc--center u-mrg--tx4">
                                                <i className="c-img-dropzone__icon icon-upload-cloud" />
                                            </label>
                                            <div className="helperText">Please make sure the file is under 10MB in .pdf or .doc format</div>
                                        </>
                                    ) }
                                    <input
                                        className={classes.invisible}
                                        type="file"
                                        accept=".pdf,.doc,.docx,application/msword,application/pdf"
                                        disabled={disableUpload}
                                        onChange={handleFileUpload}
                                        hidden
                                        id="file-upload"
                                    />
                                </div>
                                <Button
                                    color="primary"
                                    variant="text"
                                    onClick={() => window.open('/docs/sample_job_description.pdf', '_blank')}
                                >
                                    View Sample JD Format
                                </Button>
                                <div className={classes.divider}>OR</div>
                                <div className={classes.title}>Paste Text</div>
                                <TextInput
                                    disabled={Boolean(uploadedFile) || loadingFile}
                                    classes={{ root: classes.textInput }}
                                    multiline
                                    rows={10}
                                    hasCounter
                                    maxLength={25000}
                                    label="Job Description"
                                    placeholder="Place text here"
                                    onChange={handleJobDescription}
                                    value={jobDescription}
                                    counterClassName={classes.counterClass}
                                    helperTextClassName={classes.helperInputClass}
                                />
                            </div>
                        </>
                    )
                }
                {
                    currentStep === 2 && (
                        <PreviewScript
                            onClose={closeTTAiGeneratorDialog}
                            assessment={generatedAssessment}
                            open={currentStep === 2}
                            onSubmit={() => setCurrentStep(3)}
                            actionsText={{
                                next: 'Next',
                                submit: 'Review and Invite'
                            }}
                            headerActions={(
                                <Button variant="contained" color="primary" className={classes.buttonReview} onClick={() => setCurrentStep(3)}>
                                    Review and Invite
                                </Button>
                            )}
                        />
                    )
                }
                {
                    currentStep === 3 && (
                        <div className={classes.readyWrapper}>
                            <div className="title">Your assessment is ready to go!</div>
                            <div className="assessmentName">{generatedAssessment.auditionName}</div>
                            <div className="table">
                                <div className="tableHeader">
                                    <div>
                                        <img src={QuestionIcon} alt="" />
                                        Number of Questions: <span>{generatedAssessment.completedQuestions.length}</span>
                                    </div>
                                    <div>
                                        <img src={TimerIcon} alt="" />
                                        {mockAssessmentData.time} minutes
                                    </div>
                                </div>
                                <div className="tableBody">
                                    <div className="skills">
                                        <div>Skills Covered:</div>
                                        <ol>
                                            {
                                                generatedAssessment.skillsExplanation.map((el, idx) => (
                                                    <li key={idx} className="u-mrg--tx3">{el.skill}: <span>{el.explanation}</span></li>
                                                ))
                                            }
                                        </ol>
                                    </div>
                                    <div className="questionTypes">
                                        <div>Question Types:</div>
                                        {
                                            generatedAssessment.questionTypes.map((el, idx) => (
                                                <div key={idx} className="u-mrg--tx3">
                                                    {renderQuestioType(QuestionTypes[el].icon, QuestionTypes[el].label)}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <Button
                                color="primary"
                                variant="contained"
                                className={classes.footerButton}
                                onClick={() => onSave(true)}
                            >
                                Invite Candidates
                            </Button>
                            <div className="footerHelpText">
                                Find it anytime in your saved assessments
                            </div>
                            <Button
                                color="primary"
                                variant="text"
                                onClick={() => onSave()}
                            >
                                Save for Later
                            </Button>
                        </div>
                    )
                }
            </div>
        </Dialog>
    );
};

export default withStyles(stylesJs)(withRouter(TTAiGeneratorDialog));
