import React, { useContext, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import { withStyles } from '@mui/styles';
import { observer } from 'mobx-react-lite';
import Layout from 'components/layout';
import { appCtx } from 'components/appStore';
import { isEmptyObject } from 'helper/validationFunctions';
import CircularProgress from '@mui/material/CircularProgress';
import * as qs from 'query-string';

import { setupGuideCtx } from 'components/layout/SetupGuide/store';
import { setPremiumGroup } from 'requests/SettingsRequests';


const styles = theme => ({
    header: {
        backgroundColor: '#fff',
        paddingBottom: 71
    },
    headerTitleWrapper: {
        overflow: 'hidden',
        position: 'relative',
        height: 350,
        paddingBottom: 15,
        display: 'flex',
        alignItems: 'center'
    },
    headerTitle: {
        maxWidth: 730,
        zIndex: 1,
        margin: 'auto',
        color: theme.palette.grey[900],
        fontWeight: 800,
        fontSize: 32,
        lineHeight: '46px',
        textAlign: 'center'
    },
    primaryColor: {
        color: theme.palette.primary.main
    },
    loader: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        margin: 'auto'
    }
});

const Welcome = observer(({ classes, location }) => {
    const { company, isLoadingCompany, isLoadingUser, loggedUser } = useContext(appCtx);
    const { loading } = useContext(setupGuideCtx);

    useEffect(() => {
        if (location.state?.isPremium) {
            setPremiumGroup();
        }
    }, []);

    if (isLoadingCompany || isLoadingUser) {
        return (
            <Layout>
                <CircularProgress className={classes.loader} color="primary" />
            </Layout>
        );
    }

    const { isSubscriptionPaused } = company;
    const { roleType } = loggedUser;

    if (!isEmptyObject(company) && isSubscriptionPaused) {
        return (
            <Redirect to={roleType === 'team' ? '/my-account' : '/billing'} />
        );
    }

    if (loading) {
        return (
            <Layout>
                <CircularProgress className={classes.loader} color="primary" />
            </Layout>
        );
    }

    return <Redirect to="/my-assessments" />;
});

export default withRouter(withStyles(styles)(Welcome));
